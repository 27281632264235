/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
  color: var(--clr-grey-2);
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-2);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

.nav {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: relative;
  z-index: 1;
}
.nav-center {
  width: 90vw;
  max-width: var(--max-width);
}
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
  font-size: 1rem;
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius);
  border-color: transparent;
  color: white;
  background: var(--clr-black);
  cursor: pointer;
  transition: var(--transition);
}
.btn:hover {
  background: var(--clr-grey-5);
}
.nav-links {
  display: none;
}
.signin-btn {
  display: none;
}
.hero::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 65%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #1c92d2, #f2fcfe);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.hero {
  background-image: linear-gradient(to right, #1c92d2, #f2fcfe);
  margin-top: -10rem;
  position: relative;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}
.hero-center {
  width: 90vw;
  max-width: var(--max-width);
  margin-top: 10rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
}

/* .hero-info {
  justify-content: center;
} */

.hero-info h1 {
  text-transform: none;
  max-width: 500px;
  margin: auto;
  margin-bottom: 2rem;
}
.hero-info p {
  max-width: 35em;
  line-height: 1.8;
  margin: auto;
}

.hero-images {
  margin: auto;
}

.fm3-img {
  width: 20rem;
}

/* ABOUT */

.about::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 65%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #1c92d2, #f2fcfe);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.about {
  background-image: linear-gradient(to right, #1c92d2, #f2fcfe);
  position: relative;
  min-height: 100vh;
  margin-top: -10rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  text-align: center;
}
.about-center {
  width: 90vw;
  max-width: var(--max-width);
  display: grid;
  align-items: center;
  margin-top: 15rem;
}

.about-info h1 {
  text-transform: none;
  max-width: 100%;
  font-size: 3.5rem;
  margin: auto;
  margin-bottom: 2rem;
}
.about-info p {
  margin: auto;
  max-width: 35em;
  line-height: 1.8;
}
.about-images {
  display: none;
}

/* TUTORIAL */
.tutorial::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 65%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #1c92d2, #f2fcfe);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.tutorial {
  background-image: linear-gradient(to right, #1c92d2, #f2fcfe);
  position: relative;
  min-height: 100vh;

  margin-top: -10rem;
  display: grid;
  grid-template-columns: 1fr;

  justify-items: normal;

  text-align: center;
}

.tutorial-center {
  width: 90vw;
  max-width: var(--max-width);
  display: grid;
  margin-left: 1rem;
  align-items: center;
}

.tutorial-info {
  width: 90vw;
  display: grid;
  grid-template-areas:
    'header header header'
    'video1 video2 video3'
    'video4 video5 video6'
    'video7 video8 video9';
  grid-template-rows: auto;
}

.tutorial-h1 {
  grid-area: header;
  margin-bottom: 4rem;
}

.video1 {
  grid-area: video1;
}
.video2 {
  grid-area: video2;
}
.video3 {
  grid-area: video3;
}
.video4 {
  grid-area: video4;
}
.video5 {
  grid-area: video5;
}
.video6 {
  grid-area: video6;
}
.video7 {
  grid-area: video7;
}
.video8 {
  grid-area: video8;
}
.video9 {
  grid-area: video9;
}

.tutorial-div {
  margin-bottom: 5rem;
  height: 16rem;
}

.tutorial h1 {
  text-transform: none;
  max-width: 100%;
  margin-top: 15rem;
}
.tutorial p {
  /* margin: auto; */
  max-width: 30em;
  line-height: 1.8;
  margin-bottom: 0;

  /* white-space: nowrap; */
}

.tutorial-img {
  width: 7rem;
  /* display: none; */
}

.tutorial-click-div {
  cursor: pointer;
  /* border: solid 5px black; */
  height: 20rem;
  /* text-align: center; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.tutorial-click-div button {
  position: absolute;
  bottom: 0;
}

.footer {
  background-image: linear-gradient(to right, #1c92d2, #f2fcfe);
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 1;
}

.footer a {
  color: var(--clr-grey-1);
}

/* nav media query */
@media screen and (min-width: 800px) {
  .nav-center {
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: center;
  }
  .toggle-btn {
    display: none;
  }
  .signin-btn {
    display: inline-block;
  }
  .nav-links {
    display: block;
    justify-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    height: 100%;
    display: grid;
    align-items: center;
  }
  .nav-links li {
    height: 100%;
  }
  .link-btn {
    height: 100%;
    background: transparent;
    border-color: transparent;
    font-size: 1.1rem;
    color: Black;
    text-transform: capitalize;
    letter-spacing: 1px;
    width: 10rem;
  }
}

/* hero media query */
@media screen and (min-width: 800px) {
  .hero::before {
    background-size: contain;
    height: 100%;
  }
  .hero-center {
    grid-template-columns: 2fr 1fr;
  }

  .hero-info {
    justify-content: center;
  }

  .hero-info h1 {
    font-size: 4rem;
    justify-self: center;
  }
  .hero-info p {
    font-size: 1.25rem;
  }
  .hero-images {
    margin-left: 5rem;
    display: block;
    justify-self: center;
  }
  .fm3-img {
    width: 30rem;
  }
}

@media screen and (min-width: 1200px) {
  .hero-center {
    grid-template-columns: 2fr 1fr;
    align-items: end;
    padding-bottom: 12vh;
  }

  .hero-info {
    justify-content: center;
  }
  .hero-info h1 {
    max-width: 100%;
    font-size: 5.5rem;
  }

  .hero-info p {
    margin-bottom: 50%;
  }
  .hero-images {
    margin-left: 5rem;
    align-self: end;
  }
  .fm3-img {
    width: 40rem;
  }
}
@media screen and (min-width: 1400px) {
  .hero-center {
    padding-bottom: 10vh;
  }
  .hero-info {
    justify-content: center;
  }
  .hero-images {
    margin-left: 5rem;
  }
  .fm3-img {
    width: 50rem;
  }
}

/* ABOUT */

/* hero media query */
@media screen and (min-width: 800px) {
  .about::before {
    background-size: contain;
    height: 100%;
  }
  .about-center {
    grid-template-columns: 1fr;
  }

  .about-info h1 {
    font-size: 4.75rem;
    margin: auto;
    margin-bottom: 2rem;
  }
  .about-info p {
    font-size: 1.25rem;
  }
  .about-images {
    display: block;
    justify-self: center;
  }
  .fm3-img {
    width: 30rem;
  }
}

@media screen and (min-width: 1200px) {
  .about-center {
    grid-template-columns: 1fr;
    align-items: end;
    padding-bottom: 12vh;
  }
  .about-info h1 {
    max-width: 100%;
    font-size: 5.5rem;
  }
  .about-images {
    align-self: end;
  }
  .fm3-img {
    width: 40rem;
  }
}
@media screen and (min-width: 1400px) {
  .about-center {
    padding-bottom: 20vh;
  }

  .fm3-img {
    margin-top: 10rem;
    width: 50rem;
  }
}

/* ABOUT end  */

/* TUTORIALS START */

@media screen and (min-width: 800px) {
  .tutorial::before {
    background-size: contain;
    height: 100%;
  }
  .tutorial-center {
    grid-template-columns: 1fr;
    margin-left: 2rem;
  }

  .tutorial-info h1 {
    max-width: 100%;
    font-size: 3rem;
    margin-bottom: 4rem;
  }
  .tutorial-info p {
    font-size: 1.25rem;
  }

  .tutorial-div {
    height: 23rem;
  }

  .tutorial-click-div {
    height: 23rem;
  }

  .tutorial p {
    max-width: 15em;
  }
  .tutorial-images {
    display: block;
    justify-self: center;
  }
  .tutorial-img {
    width: 15rem;
  }
}

@media screen and (min-width: 1200px) {
  .tutorial-center {
    grid-template-columns: 1fr;
    align-items: end;
    padding-bottom: 12vh;
    margin-left: 3rem;
  }
  .tutorial-info h1 {
    max-width: 100%;
    font-size: 5.5rem;
  }

  .tutorial-div {
    height: 27rem;
  }

  .tutorial-click-div {
    height: 27rem;
  }

  .tutorial p {
    max-width: 100%;
  }
  .tutorial-images {
    align-self: end;
  }
  .tutorial-img {
    width: 20rem;
  }
}
@media screen and (min-width: 1400px) {
  .tutorial-center {
    padding-bottom: 20vh;
    margin-left: 4rem;
  }
  .tutorial-img {
    width: 26rem;
  }

  .tutorial-div {
    height: 32rem;
  }
  .tutorial-click-div {
    height: 32rem;
  }
}
/* TUTORIALS END */

/* sidebar */
.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  visibility: hidden;
  z-index: -1;
  transition: var(--transition);
  transform: scale(0);
  background: rgba(0, 0, 0, 0.5);
}
.sidebar-wrapper.show {
  visibility: visible;
  z-index: 2;
  transform: scale(1);
}
.sidebar {
  width: 90vw;
  height: 95vh;
  max-width: var(--fixed-width);
  background: var(--clr-white);
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  position: relative;
  padding: 4rem 2rem;
}
.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: var(--clr-grey-5);
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.sidebar-nav-links {
  cursor: pointer;
}
.sidebar article {
  margin-bottom: 2rem;
}
.sidebar-sublinks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.25rem;
}
.sidebar-sublinks a {
  display: block;
  color: var(--clr-grey-1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.sidebar-sublinks svg {
  color: var(--clr-grey-5);
  margin-right: 1rem;
  cursor: pointer;
}

@media screen and (min-width: 800px) {
  .sidebar-wrapper {
    display: none;
  }
}

/* links */
.submenu {
  background: var(--clr-white);
  box-shadow: var(--dark-shadow);
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  display: none;
  padding: 2rem;
  border-radius: var(--radius);
  transition: var(--transition);
}
.submenu::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--clr-white);
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.submenu.show {
  display: block;
}

.submenu-center {
  display: grid;
  gap: 0.25rem 2rem;
}
.col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.col-4 {
  grid-template-columns: repeat(2, 1fr);
}
.submenu h4 {
  margin-bottom: 1.5rem;
}
.submenu-center a {
  width: 10rem;
  display: block;
  color: var(--clr-grey-1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.submenu-center svg {
  color: var(--clr-grey-5);
  margin-right: 1rem;
}
